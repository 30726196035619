/* This should be a last resort when no other styling works or when you want to override some styles globally */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #e5e5e5;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

.score-label > tspan {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  fill: #26145c !important;
}

.mantine-Container-root {
  max-width: 100%;
  margin-left: 10%;
  margin-right: 10%;
}

thead {
  font-family: Montserrat;
  background-color: #e3e1ea;
}

th {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #26145ccc !important;
}

tbody {
  font-family: Montserrat;
}

td {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #26145ccc !important;
  border: 1px solid #9289ad !important;
}
